const chart5 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: {
            top: 10,
            left: 120,
            right: 10,
            bottom: 30,
        },
        xAxis: {
            type: 'value',
            splitLine: { // 分隔线
                show: false,
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.5)',
                },
            },
            axisLabel: {
                fontSize: '12px',
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                fontSize: '14px',
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.5)',
                },
            },
            width: 200,
            data: [
                '丰南园区',
                '玉田园区',
                '路北园区',
                '开平（蔚徕）园区',
                '曹妃甸园区',
                '迁安园区',
                // '开平园区',
                '路南园区',
                '高新分园区',
                '核心园区',
            ],
        },
        series: [
            {
                name: '园区面积',
                type: 'bar',
                barWidth: 18,
                data: [
                    // 1823.8,
                    10700,
                    4080,
                    3000,
                    6000,
                    5000,
                    13000,
                    15000,
                    6200,
                    21000
                ],
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                }
            },
        ]
    };
};
export default chart5
