const chart1 = () => {
    const data = [
        {"name": "核心园区", "服务人次": "0.9153", "服务用人单位": "1.6843"},
        {"name": "高新分园区", "服务人次": "0.047", "服务用人单位": "0.0458"},
        {"name": "路南园区", "服务人次": "0.5922", "服务用人单位": "0.2997"},
        // {"name": "开平园区", "服务人次": "0.319", "服务用人单位": "0.053"},
        {"name": "迁安园区", "服务人次": "1.1651", "服务用人单位": "0.0069"},
        {"name": "曹妃甸园区", "服务人次": "0.555", "服务用人单位": "0.1456"},
        {"name": "开平（蔚徕）园区", "服务人次": "0.303", "服务用人单位": "0.507"},
        {"name": "路北园区", "服务人次": "0.0467", "服务用人单位": "0.011"},
        {"name": "玉田园区", "服务人次": "0.0079", "服务用人单位": "0.0051"},
        {"name": "丰南园区", "服务人次": "0.8822", "服务用人单位": "0.0375"},
    ]
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: {
            top: 40,
            left: 60,
            right: 60,
            bottom: 55,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map((it) => it.name),
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.5)',
                },
            },
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.5)',
                },
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        series: [
            {
                data: data.map((it) => (Number(it['服务人次']) * 10000).toFixed()),
                symbol: 'circle',
                symbolSize: 8,
                type: 'line',
                emphasis: {
                    focus: 'series'
                },
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                    width: 1,
                },
                itemStyle: {
                    borderWidth: 1,
                    normal: {
                        borderColor: 'rgb(50,202,250,.4)',
                        borderWidth: 2,
                        color: 'rgb(29,146,182)',//拐点颜色
                    },
                    emphasis: {
                        color: 'rgba(50,202,250)'//hover拐点颜色定义
                    }
                },
                markPoint: {
                    data: [
                        // {type: 'max', name: 'Max'},
                    ],
                    label: {
                        color: 'rgba(255,255,255,0.8)',
                        fontSize: 10
                    },
                    itemStyle: {
                        color: 'rgba(240,80,80,0.5)',
                    },
                    symbolOffset: ['0', '-10%'],
                },
            },
            {
                data: data.map((it) => (Number(it['服务用人单位']) * 10000).toFixed()),
                symbol: 'circle',
                symbolSize: 8,
                type: 'line',
                emphasis: {
                    focus: 'series'
                },
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                    width: 1,
                },
                itemStyle: {
                    borderWidth: 1,
                    normal: {
                        borderColor: 'rgb(50,202,250,.4)',
                        borderWidth: 2,
                        color: 'rgb(29,146,182)',//拐点颜色
                    },
                    emphasis: {
                        color: 'rgba(50,202,250)'//hover拐点颜色定义
                    }
                },
                markPoint: {
                    data: [
                        // {type: 'max', name: 'Max'},
                    ],
                    label: {
                        color: 'rgba(255,255,255,0.8)',
                        fontSize: 10
                    },
                    itemStyle: {
                        color: 'rgba(240,80,80,0.5)',
                    },
                    symbolOffset: ['0', '-10%'],
                },
            }
        ]
    };
};
export default chart1
