const chart4 = () => {
    return {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: [
            '园区名称', '招聘会场次', '线上招聘会场次', '线下招聘会场次', '发布职位数', '就业岗位', '达成意向',
        ],
        rowNum: 4,
        data: [
            // ['核心园区', 110, 85, 25, 4.1417, 3.1174, 1.845],
            // ['高新分园区', 23, 10, 13, 0.1102, 0.1054, 0.2043],
            // ['路南园区', 156, 55, 101, 1.4107, 0.7687, 0.6009],
            // // ['开平园区', 265, 70, 195, 5.879, 2.532, 1.712],
            // ['迁安园区', 11, 0, 11, 0.004, 0.0036, 0.5511],
            // ['曹妃甸园区', 329, 294, 35, 0.5262, 0.4329, 0.831],
            // ['开平（蔚徕）园区', 46, 16, 30, 5.4796, 2.6997, 0.063],
            // ['路北园区', 177, 90, 87, 0.2118, 0.0878, 0.1137],
            // ['玉田园区', 0, 0, 0, 0, 0, 0],
            // ['丰南园区', 169, 105, 64, 0.3879, 0.1742, 6.2595],
            ['核心园区', 110, 85, 25, 4.1417, 3.1174, 0],
            ['高新分园区', 23, 10, 13, 0.1102, 0.1054, 0],
            ['路南园区', 156, 55, 101, 1.4107, 0.7687, 0],
            // ['开平园区', 265, 70, 195, 5.879, 2.532, 1.712],
            ['迁安园区', 11, 0, 11, 0.004, 0.0036, 0],
            ['曹妃甸园区', 329, 294, 35, 0.5262, 0.4329, 0],
            ['开平（蔚徕）园区', 46, 16, 30, 5.4796, 2.6997, 0],
            ['路北园区', 177, 90, 87, 0.2118, 0.0878, 0],
            ['玉田园区', 0, 0, 0, 0, 0, 0],
            ['丰南园区', 169, 105, 64, 0.3879, 0.1742, 0],
         
        ].map((it) => {
            it[4] = ((it[4] as number) * 10000).toFixed()
            it[5] = ((it[5] as number) * 10000).toFixed()
            it[6] = ((it[6] as number) * 10000).toFixed()
            return it
        })
    };
};
export default chart4
